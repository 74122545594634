/* Variable fonts usage:
:root { font-family: "Inter", sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: "InterVariable", sans-serif; font-optical-sizing: auto; }
} */
@font-face {
   font-family: InterVariable;
   font-style: normal;
   font-weight: 100 900;
   font-display: swap;
   src: url('InterVariable.woff2') format('woff2');
}

/* static fonts */

@font-face {
   font-family: 'InterDisplay';
   font-style: normal;
   font-weight: 400;
   font-display: swap;
   src: url('InterDisplay-Regular.woff2') format('woff2');
}

@font-face {
   font-family: 'InterDisplay';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
   src: url('InterDisplay-Medium.woff2') format('woff2');
}

@font-face {
   font-family: 'InterDisplay';
   font-style: normal;
   font-weight: 600;
   font-display: swap;
   src: url('InterDisplay-SemiBold.woff2') format('woff2');
}
