@import url('../fonts/Inter/inter.css');

:root {
   font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
   :root {
      font-family: 'InterVariable', sans-serif;
      font-optical-sizing: auto;
   }
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'InterVariable', sans-serif;
}
html {
   scroll-behavior: smooth;
}

body {
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
   height: 100%;
}
